<template>
  <Btn @click="$emit('click')" class="bouton-fleche-gauche"/>
</template>

<style lang="scss" scoped>
.bouton-fleche-gauche {
  cursor: pointer;
  user-select: none;

  .contour {
    fill: #000;
  }
  &:hover .contour {
    fill: #3b3b3b;
  }
  &:active .contour {
    fill: #000;
  }

  $color: #0998c3;
  .background {
    fill: $color;
  }
  &:hover .background {
    fill: lighten($color, 3%);
  }
  &:active .background {
    fill: darken($color, 5%);
  }

  .inside {
    fill: #000;
  }
  &:hover .inside {
    fill: #222222;
  }
  &:active .inside {
    fill: #000;
  }
}


</style>

<script>
import Btn from '@/resources/images/inline.fleche_tuto_gauche.svg';

export default {
  name: 'BoutonFlecheGauche',
  components: {
    Btn: Btn,
  },
}
</script>
